import React from "react";



// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import IsoPageHeader from "components/Headers/IsoPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter";



function Iso() {



    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (

        <div>
            <ExamplesNavbar />
            <IsoPageHeader />
            {/* <Examples/>  */}
            <div className="section section-team text-center">
                <Container>

                    <div>
                        <h2 className="title">ISO Certification</h2>

                        <Row className="justify-content-center">
                            {/* <h3 className="title">The consequences of consuming contaminated food can be serious. ISO's food safety management standards assist organizations in identifying and controlling food safety hazards while collaborating with other ISO management standards such as ISO 9001. ISO 22000, which is applicable to all types of producers, adds a layer of assurance to the global food supply chain, permitting products to cross borders and bringing people food they can trust.</h3> */}
                            <h5 className="description" style={{ color: "#414242" }}>

                                The consequences of consuming contaminated food can be serious. <br />
                                ISO's food safety management standards assist organizations in identifying and controlling food safety hazards while collaborating with other ISO management standards such as ISO 9001.
                                <br />
                                ISO 22000, which is applicable to all types of producers, adds a layer of assurance to the global food supply chain, permitting products to cross borders and bringing people food they can trust.
                                    </h5>

                            <Col md="6" offset-md-3>
                                <div className="team-player" align="center">
                                    <br />
                                    <br />
                                    <img
                                        alt="..."
                                        src={require("assets/img/iso.jpg")}
                                    ></img>
                                    {/* <h3 className="title">The consequences of consuming contaminated food can be serious. ISO's food safety management standards assist organizations in identifying and controlling food safety hazards while collaborating with other ISO management standards such as ISO 9001. ISO 22000, which is applicable to all types of producers, adds a layer of assurance to the global food supply chain, permitting products to cross borders and bringing people food they can trust.</h3> */}
                                    <p className="category text-info"></p>




                                </div>
                            </Col>



                        </Row>
                    </div>
                </Container>
            </div>

            <DarkFooter />

        </div>
    );
}

export default Iso;
