import React from 'react'

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import CoolingPageHeader from "components/Headers/CoolingPageHeader.js";



import DarkFooter from 'components/Footers/DarkFooter';

import {

    Container,
    Row,
    Col,
} from "reactstrap";


const Cooling = () => {

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (

        <>
            <ExamplesNavbar />
            <div className="wrapper">
                <CoolingPageHeader />
                <div className="section section-about-us">

                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">Saifan's Cooling Systems </h2>
                                <h5 className="description" style={{ color: "#414242" }}>
                                    Different techniques are available to accomplish the cooling process for fresh produce, which includes room cooling, forced-air cooling, and hydrocooling.
                                    <br />
                                    Forced air precooling is the most applied technique and is customized to many foods to protect the quality.


                                </h5>
                                <h5 className="description" style={{ color: "#414242" }}>
                                    In addition to protecting quality, postharvest cooling provides marketing flexibility by making it possible to market at the optimum time.
                                    <br />
                                    Being able to cool and store produce eliminates the need to market immediately after harvest, which can be an advantage for high-volume growers as well as pick-your-own (P-Y-O) operators who wish to supply restaurants and grocery stores.
                                    <br />

                                    To select the best cooling method, it is necessary to understand the basic principles of cooling.
                                    <br />
                                    <br />
                                    <br />

                                    The choice of cooling method depends on the following factors:
                                    <br />
                                    <br />
                                    <b>The nature of the product.</b>

                                     Different types of produce have different cooling requirements. For example, strawberries and broccoli require near-freezing temperatures, whereas summer squash or tomatoes would be damaged by such low temperatures. Likewise, because of problems that can be caused by wetting of certain products, hydrocooling or icing may not be appropriate.
                                    <br />
                                    <br />

                                    <b>Product packaging requirements.</b> The best choice of cooling method may depend on whether the produce is in a box, bin, or bag. The package design can have an effect on the method and rate of cooling.
                                    <br />
                                    <br />

                                    <b>Product flow capacity.</b> Some methods of cooling are much faster than others. If the volume of produce to be cooled per season, per day, or per hour is large, it may be necessary to use a faster cooling method than would be used for lower volumes.




                                </h5>
                            </Col>
                        </Row>

                    </Container>
                </div>
                <div className="section section-team text-center">
                    <Container>

                        <div>
                            <h3 className="title">Level I</h3>

                            <Row>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/rf222.jpg")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>




                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/rf2.png")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/rf2.jpg")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>



                                    </div>
                                </Col>
                            </Row>
                            {/*
                    <Row>
                      <Col md="12">
                        <div className="team-player">
                          <img
                            alt="..."
                            src={require("assets/img/ref4.jpg")}
                          ></img>
                          <p className="category text-info"></p>
        
        
        
                        </div>
                      </Col>
                    </Row>
                  */}
                        </div>
                    </Container>
                </div>
                <div className="section section-team text-center">
                    <Container>

                        <div>
                            <h3 className="title">Level II</h3>

                            <Row>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/rf1.jpg")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>




                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/rf11.JPG")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/rf111.JPG")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>



                                    </div>
                                </Col>
                            </Row>
                            {/*
                <Row>
                  <Col md="12">
                    <div className="team-player">
                      <img
                        alt="..."
                        src={require("assets/img/ref4.jpg")}
                      ></img>
                      <p className="category text-info"></p>
    
    
    
                    </div>
                  </Col>
                </Row>
              */}
                        </div>
                    </Container>
                </div>
                <div className="section section-team text-center">
                    <Container>

                        <div>
                            <h3 className="title">Equipment</h3>

                            <Row>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/rf3.png")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>




                                    </div>
                                </Col>


                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/rf333.JPG")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>



                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/h.JPG")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>



                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/l.JPG")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>




                                    </div>
                                </Col>


                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/l2.JPG")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>



                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="team-player">
                                        <img
                                            alt="..."
                                            src={require("assets/img/l3.JPG")}
                                        ></img>
                                        {/* <h3 className="title">Garlic</h3> */}
                                        <p className="category text-info"></p>



                                    </div>
                                </Col>
                            </Row>
                            {/*
            <Row>
              <Col md="12">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/ref4.jpg")}
                  ></img>
                  <p className="category text-info"></p>



                </div>
              </Col>
            </Row>
          */}
                        </div>
                    </Container>
                </div>
            </div >
            <DarkFooter />


        </>
    )
}

export default Cooling;
