import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

import {

  Col,
} from "reactstrap";

function Cards() {
  return (
    <div className='cards'>
      <Col className="ml-auto mr-auto text-center" md="8">
        <h2 className="title">Potatoes</h2>

        <h5 className="description" style={{ color: "#414242" }}>
          Potato is a root vegetable, known also as Solanum tuberosum. While it is a small plant with large leaves, our products are the perfect choice for consumption. You can eat them Mashed, Roasted, Baked, and Sautéed. Saifan potatoes are your best choice for Crisps, Deep-Fried, and French Fries.
<br />
          <br />
Don’t forget to add Saifan potatoes as part of your salad or soup!
    </h5>
      </Col>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>

            <CardItem
              src='images/LOGO_POTATO.jpg'
              text='Saifan Potatoes'
              label='Saifan'

            /><CardItem
              src='images/img-7.jpg'
              text='Saifan Potatoes'
              label='Saifan'

            />
            <CardItem
              src='images/img-1.jpg'
              text='Saifan Potatoes'
              label='Saifan'

            />
          </ul>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Onions</h2>

            <h5 className="description" style={{ color: "#414242" }}>
              Saifan offers different types of onion, known for its strong flavor and a very distinctive smell.
<br />
     Saifan onions are the perfect choice to be used, Fried, cooked, raw onions, roasted and pickled with chips or salad.
    </h5>
          </Col>
          <ul className='cards__items'>
            <CardItem
              src='images/LOGO_ONION.jpg'
              text='Saifan Onions'
              label='Saifan'

            />
            <CardItem
              src='images/img-3.jpg'
              text='Saifan Onions'
              label='Saifan'

            />
            <CardItem
              src='images/img-8.jpg'
              text='Saifan Onions'
              label='Saifan'

            />
            <CardItem
              src='images/img-9.jpg'
              text='Saifan Onions'
              label='Saifan'

            />
          </ul>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Garlic</h2>

            <h5 className="description" style={{ color: "#414242" }}>
              Alongside garlic nutrition benefits, Saifan Garlic offers a flavoring addition to your table. While you can make garlic butter or garlic toast, you can flavor many foods such as sauces, meats, soups, and salad dressings.
            </h5>
          </Col>
          <ul className='cards__items'>
            <CardItem
              src='images/LOGO_GARLIC.jpg'
              text='Saifan Garlic'
              label='Saifan'

            />
            <CardItem
              src='images/img-10.jpg'
              text='Saifan Garlic'
              label='Saifan'

            />
            <CardItem
              src='images/img-11.jpg'
              text='Saifan Garlic'
              label='Saifan'

            />
          </ul>

          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Fruits</h2>

            <h5 className="description" style={{ color: "#414242" }}>
              Our table of fruits contain a wide range of fleshy fruits types:
            <br />
            True berry, pepo, hesperidium, aggregate fruit, multiple fruits, and accessory fruit.
            </h5>
          </Col>

          <ul className='cards__items'>
            <CardItem
              src='images/watermelon.jpg'
              text='Saifan watermelon'
              label='Saifan'

            />
            <CardItem
              src='images/img-5.jpg'
              text='Saifan plum'
              label='Saifan'

            />
            <CardItem
              src='images/img-4.jpg'
              text='Saifan peach'
              label='Saifan'

            />
            <CardItem
              src='images/img-6.jpg'
              text='Saifan oranges'
              label='Saifan'

            />



          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
