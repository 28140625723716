import React from "react";
import CarouselSection from "../index-sections/Carousel"



// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter";



function LandingPage() {



  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (

    <div>
      <ExamplesNavbar />
      <LandingPageHeader />
      {/* <Examples/>  */}

      <CarouselSection />
      <div className="section section-about-us">
        <div className="section section-team text-center">
          <Container>

            <div>
              <h2 className="title">ISO Certification</h2>

              <Row className="justify-content-center">

                <Col md="6" offset-md-3>
                  <div className="team-player" align="center">
                    <img
                      alt="..."
                      src={require("assets/img/iso.jpg")}
                    ></img>
                    <br />
                    <br />
                    <Button
                      color="info"
                      href="#pablo"
                      onClick={() => window.location.href = '/iso-certification'}
                    >
                      See More
                </Button>
                    {/* <h3 className="title">Garlic</h3> */}
                    <p className="category text-info"></p>




                  </div>
                </Col>



              </Row>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Saifan Cooling</h2>
              <h5 className="description" style={{ color: "#414242" }}>
                Eating a diet rich in fruits and vegetables can help
                lower your risk of many health conditions, including high blood pressure,
                heart disease, stroke, and certain kinds of cancers.
                <br />
                <br />But if they’re not handled properly,
                fruits and veggies can also become a source of food-borne pathogens.
                For example, they can become contaminated with listeria, salmonella, or other bacteria.
              <br />
                <br />

              That’s why it’s so important to learn how to clean and store fruits and veggies.
              <br />
                <br />
                After harvest, vegetable quality is maintained through cooling and by
                slowing down the rate of respiration.
              For safety reasons, we should refrigerate or freeze any fruit or vegetable.
              Store products in a plastic bag or sealed container to preserve its freshness and limit its contact with air.</h5>
              <Button
                color="info"
                href="#pablo"
                onClick={() => window.location.href = '/cooling'}
              >
                See More
            </Button>
            </Col>

          </Row>

          <div className="separator separator-primary"></div>

          {/* <div className="section-story-overview">
 <Row>

              
              <Col md="5">
                <div
                  className="image-container image-right"
                  style={{
                    opacity: "0"
                  }}
                ></div>

                <h3 style={{ color: "#414242" }}>
                  So,
                  </h3>
                <p style={{ color: "#414242" }}>
                  Saifan, this little boy then, succeeded to deliver through years, 100% Lebanese products ISO certified, known for the highest quality and following the high level of standards.
                  </p>

              </Col>
               

            </Row>
            
          </div>
           */}
        </Container>
      </div>
      <div className="section section-team text-center">
        <Container>

          <div>
            <Row>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/ref3.jpg")}
                  ></img>
                  {/* <h3 className="title">Garlic</h3> */}
                  <p className="category text-info"></p>




                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/ref1.jpg")}
                  ></img>
                  {/* <h3 className="title">Garlic</h3> */}
                  <p className="category text-info"></p>
                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/ref2.jpg")}
                  ></img>
                  {/* <h3 className="title">Garlic</h3> */}
                  <p className="category text-info"></p>



                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="section section-team text-center">
        <Container>
          <h2 className="title">Our products</h2>
          <div>
            <Row>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/potatoe1.jpg")}
                  ></img>
                  <h3 className="title">Potatoes</h3>
                  <p className="category text-info"></p>

                  <Button
                    color="info"
                    href="#pablo"
                    onClick={() => window.location.href = '/products'}
                  >
                    See More
                  </Button>


                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/onion1.jpg")}
                  ></img>
                  <h3 className="title">Onions</h3>
                  <p className="category text-info"></p>

                  <Button
                    color="info"
                    href="#pablo"
                    onClick={() => window.location.href = '/products'}
                  >


                    See More
                    </Button>

                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/garlic1.jpg")}
                  ></img>
                  <h3 className="title">Garlic</h3>
                  <p className="category text-info"></p>

                  <Button
                    color="info"
                    href="#pablo"
                    onClick={() => window.location.href = '/products'}
                  >
                    See More
                    </Button>

                </div>
              </Col>
            </Row>
            <Row>
              <Col md="2"></Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/watermelon.jpg")}
                  ></img>
                  <h3 className="title">Watermelon</h3>
                  <p className="category text-info"></p>

                  <Button
                    color="info"
                    href="#pablo"
                    onClick={() => window.location.href = '/products'}
                  >
                    See More
                </Button>


                </div>

              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/orange.jpg")}
                  ></img>
                  <h3 className="title">Orange</h3>
                  <p className="category text-info"></p>

                  <Button
                    color="info"
                    href="#pablo"
                    onClick={() => window.location.href = '/products'}
                  >
                    See More
              </Button>


                </div>

              </Col>
            </Row>
            <Row>
              <Col md="2"></Col>

              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/plum.jpg")}
                  ></img>
                  <h3 className="title">Plum</h3>
                  <p className="category text-info"></p>

                  <Button
                    color="info"
                    href="#pablo"
                    onClick={() => window.location.href = '/products'}
                  >


                    See More
                  </Button>

                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    src={require("assets/img/peach.jpg")}
                  ></img>
                  <h3 className="title">Peach</h3>
                  <p className="category text-info"></p>

                  <Button
                    color="info"
                    href="#pablo"
                    onClick={() => window.location.href = '/products'}
                  >
                    See More
                  </Button>

                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4"></Col>
              <Col md="4">
                <div className="team-player ">
                  <img
                    alt="..."
                    width="50%"
                    src={require("assets/img/tri-advise.png")}
                  ></img>
                  {/* <h3 className="title"></h3>  */}

                  <p className="category text-info">Powered by Tri-Advise</p>


                  <div className="text-center">
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="https://www.facebook.com/TriAdvise/"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="https://www.instagram.com/tri_advise"
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="https://wa.me/+96181906656"
                      target="_blank"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </Button>

                  </div>
                </div>
              </Col>
              <Col md="4"></Col>
            </Row>
          </div>
        </Container>


      </div>
      <DarkFooter />

    </div>
  );
}

export default LandingPage;
