import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function ProfilePageHeader() {
  let pageHeader = React.createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });
  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        filter-color="blue"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/productsheader.jpg") + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>

          <h2 className="title">Seed and Crop Protection</h2>
          <p className="category"></p>
          <div className="content">
            <div className="social-description">
              <p> WORLDWIDE DELIVERY</p>

            </div>
            <div className="social-description">


            </div>
            <div className="social-description">
              <p>GUARANTEED QUALITY</p>
            </div>
            <div className="social-description">


            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ProfilePageHeader;
