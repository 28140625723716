import React from 'react'

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import HistoryPageHeader from "components/Headers/HistoryPageHeader.js";



import DarkFooter from 'components/Footers/DarkFooter';

import {

  Container,
  Row,
  Col,
} from "reactstrap";


const History = () => {

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (

    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <HistoryPageHeader />
        <div className="section section-about-us">

          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Our History</h2>
                <h5 className="description" style={{ color: "#414242" }}>
                  It started when a 13 years old boy, decided to tackle life obstacles with a brave heart, a fearless one, in order to make difference. He succeeded after all to build a worldwide company known for its wide range of Fresh Vegetables & Fruits with several types under each title.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/history.jpg") + ")",
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      <b>"Starting with planting till the product is packed and ready to be exported around the globe, what we promise, is definitely what we deliver!"</b>
                      <br></br>
                      <br></br>
                      <small>-Saifan</small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/aboutbg.jpg") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      opacity: "0"
                    }}
                  ></div>
                  <h3 style={{ color: "#414242" }}>
                    So,
                  </h3>
                  <p style={{ color: "#414242" }}>
                    Saifan, this little boy then, succeeded to deliver through years, 100% Lebanese products ISO certified, known for the highest quality and following the high level of standards.
                  </p>

                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <DarkFooter />


    </>
  )
}

export default History;
