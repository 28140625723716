/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a
                href="/"
              >
                Saifan
              </a>
            </li>
            <li>
              <a
                href="/our-history"

              >
                Our History
              </a>
            </li>
            <li>
              <a
                href="/cooling"

              >
                Cooling
            </a>
            </li>
            <li>
              <a
                href="/about"

              >
                About
            </a>
            </li>
            <li>
              <a
                href="/iso-certification"

              >
                ISO
          </a>
            </li>
            <li>
              <a
                href="/contact"

              >
                Contact Us
              </a>
            </li>

          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()},
          Powered by
          <a
            href="https://tri-advise.com"
            target="_blank"

          >  Tri-Advise</a>


        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
