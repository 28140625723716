import React from "react";
// reactstrap components
import {
  Container
} from "reactstrap";
// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header page-header-small">

        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/aboutheader.jpg") + ")",
          }}
          ref={pageHeader}
        ></div>

        <div className="content-center">
          <Container>
            <h1 className="title">Welcome to Saifan Lands</h1>
            {/*
           <h2 className="title"> Powered by Tri-Advise</h2> 

            <div className="text-center">
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.facebook.com/TriAdvise/"
                target="_blank"  
              > 
                <i className="fab fa-facebook-square"></i>
              </Button>
              <Button
              className="btn-icon btn-round"
              color="info"
              href="https://www.instagram.com/tri_advise"
              target="_blank"  
            > 
            <i className="fab fa-instagram"></i>
            </Button>
            <Button
            className="btn-icon btn-round"
            color="info"
            href="https://wa.me/+96181906656"
            target="_blank"  
          > 
          <i className="fab fa-whatsapp"></i>
          </Button>
              
            </div>
             */}
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
