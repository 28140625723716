import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// sections for this page

import LandingPage from "./examples/LandingPage.js";
import ReactGA from 'react-ga';
ReactGA.initialize('G-2R6S2ELREK');
ReactGA.pageview(window.location.pathname + window.location.search);


function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div>
          <LandingPage />
        </div>
    </>
  );
}

export default Index;
