
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
//pages
import Index from "views/Index.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import History from "views/examples/History";
import Cooling from "views/examples/Cooling";
import Iso from "views/examples/Iso";
import Contact from "views/examples/Contact";
import Products from "views/examples/Products";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route exact path="/" render={(props) => <Index {...props} />} />

        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/contact"
          render={(props) => <Contact {...props} />}
        />
        <Route
          path="/our-history"
          render={(props) => <History {...props} />}
        />
        <Route
          path="/cooling"
          render={(props) => <Cooling {...props} />}
        />
        <Route
          path="/iso-certification"
          render={(props) => <Iso {...props} />}
        />
        <Route
          path="/about"
          render={(props) => <ProfilePage {...props} />}
        />

        <Route
          path="/products"
          render={(props) => <Products {...props} />}
        />

      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
