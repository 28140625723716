import DarkFooter from 'components/Footers/DarkFooter';
import ContactPageHeader from 'components/Headers/ContactPageHeader';
import React from 'react'
import './Contact.css';

import {
    
    Container,
    Row,
    Col,
  } from "reactstrap";



const Contact = () => {
    
    React.useEffect(() => {
      document.body.classList.add("landing-page");
      document.body.classList.add("sidebar-collapse");
      document.documentElement.classList.remove("nav-open");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      return function cleanup() {
        document.body.classList.remove("landing-page");
        document.body.classList.remove("sidebar-collapse");
      };
    }, []);
    return (
        <div>
        <ContactPageHeader/>
        <div className="section section-contact-us text-center">
        <Container>
          <h2 className="title">Contact us</h2>
          <p className="description">.</p>
          <Row>
            <Col className="text-center ml-auto mr-auto" lg="6" md="8">
          
            </Col>
            <Col className="ml-auto mr-auto text-center" md="8">
        
            <h5 className="description" style={{color:"#414242"}}>
            We're here to help you, so please don't hesitate to pick up the phone or drop us an email. 
            <br/>
            <br/>
            Our customer services team is available to address your query as efficiently as possible, give you expert product advice, and answer any questions about orders or our service.            </h5>
           </Col>
          </Row>
        </Container>
      </div>
      

      <div className="contact3 py-5">
    
        <div className="row no-gutters">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div>
                </div>
              </div>
              <div className="col-lg-6">
               
              </div>
              <div className="col-lg-12">
                <div className="card mt-4 border-0 mb-4">
                  <div className="row">
                  <div className="col-lg-4 col-md-4">
                  <div className="card-body d-flex align-items-center c-detail">
                    <div className="mr-3 align-self-center">
                      <img alt="" src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon3.png"/>
                    </div>
                    <div className="">
                      <h6 className="font-weight-medium">Email</h6>
                      <p className="">
                     saifan.agriculture@gmail.com 
                      </p>
                    </div>
                  </div>
                </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="card-body d-flex align-items-center c-detail">
                        <div className="mr-3 align-self-center">
                          <img alt="" src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon2.png"/>
                        </div>
                        <div className="">
                          <h6 className="font-weight-medium">Phone</h6>
                          <p className="">+961 3 33 14 26 </p>
                          <p className="">+961 3 40 67 41 </p>
                          <p className="">+961 70 25 92 03 </p>



                        </div>
                      </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-4">
                    <div className="card-body d-flex align-items-center c-detail pl-0">
                      <div className="mr-3 align-self-center">
                        <img alt=""  src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon1.png"/>
                      </div>
                      <div className="">
                        <h6 className="font-weight-medium">Address</h6>
                        <p className="">Bekaa, Lebanon</p>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      


        <DarkFooter/>
        </div>
    )
}

export default Contact
