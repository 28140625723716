import DarkFooter from 'components/Footers/DarkFooter'
import ProductsPageHeader from 'components/Headers/ProductsPageHeader'
import React from 'react'
import Cards from './Cards'


const Products = () => {
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
          document.body.classList.remove("landing-page");
          document.body.classList.remove("sidebar-collapse");
        };
      }, []);
    return (
        <div>
           <ProductsPageHeader/>
           <Cards/>
           <DarkFooter/>

        </div>
        
    )
}

export default Products
